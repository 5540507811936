import React, { FunctionComponent, CSSProperties } from 'react';
import { Images } from '../../assets/Images';
import styled from 'styled-components';
import { useMeasure } from '../../hooks';
import { FormattedMessage } from 'react-intl';
import { colors } from '../../theme/colors';

const Container = styled.div`
  position: relative;
`;

const Bubble = styled.div`
  right: 0;
  width: 232px;
  height: auto;
  padding: 12px;
  box-shadow: 0 0 2px 0 rgba(30, 78, 95, 0.7);
  background-color: #f8fdff;
  position: absolute;
  z-index: 999;
  border-radius: 10px;
`;

const CloseBubble = styled.div`
  color: #ffffff;
  width: fit-content;
  padding: 7px 26px;
  border-radius: 15.5px;
  background-color: ${colors['dark-slate-blue']};
  font-family: Inter500;
  font-size: 14px;
  float: right;
  margin-top: 17px;
  cursor: pointer;
`;

const TooltipTriangle = styled.img`
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  bottom: -10px;
  z-index: 2147483643;
`;

const Text = styled.span`
  display: inline-block;
  color: ${colors['dark-slate-blue']};
  font-family: Inter500;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
`;

interface TooltipProps {
  children: any
  onCloseClick(): void
  visible?: boolean
  offset?: number
  containerStyle?: CSSProperties
  messageId: string
}

export const Tooltip: FunctionComponent<TooltipProps> = ({
  children,
  offset = 0,
  onCloseClick,
  containerStyle,
  visible = true,
  messageId,
}: TooltipProps) => {
  const { bounds, ref } = useMeasure();
  const CIRCLE_HEIGHT = 10;

  console.log('[driver] bounds', bounds)

  return (
    <Container style={containerStyle}>
      <Bubble
        ref={ref}
        style={{
          top: `${ -1 * (bounds.height + CIRCLE_HEIGHT + offset)}px`,
          display: visible ? 'inline' : 'none',
        }}
      >
        <Text><FormattedMessage id={messageId} /></Text>
        <CloseBubble onClick={onCloseClick}>
          <FormattedMessage id="drv.cta-button.tooltip.action" />
        </CloseBubble>
        <TooltipTriangle src={Images.tooltipCircle} />
      </Bubble>
      {children}
    </Container>
  );
};
