import React, { CSSProperties } from 'react'
import './ErrorHeader.css'
import { Button } from '../Button/Button'
import { Toaster } from 'react-hot-toast'
import EventEmitter from 'eventemitter3';

interface Props {
  actionOrder: number
  action: string
  actionText: string
  code: string | undefined
  source: string | undefined
  constr: { width: number; ratio: number }
  emitter: EventEmitter
}
const fptCActionsButtonContainerStyle: CSSProperties = {
  fontFamily: 'Inter500',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  height: '25%'
};

const ErrorActions: React.FunctionComponent<Props> = ({ actionOrder, action, actionText, emitter }) => {
  return (
    <>
      <div>
        <Toaster />
      </div>
      <div style={fptCActionsButtonContainerStyle} className="fpt-c-actions-button-container">
        <Button
          variant={actionOrder == 1 ? 'wide-primary-round' : 'link-primary'}
          onClick={() => emitter.emit(action)}>
          {actionText}
        </Button>
      </div>
    </>
  )
}

export default ErrorActions
