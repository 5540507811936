import { GUCA1, GUCA2, GUCA3, MADB1, MADB2, MECO1, MECO2, MEFR0, MEST1, MEST2, MEST4, MEST5, MAST1, MAST2, YURI, MAVA1 } from './assets/images';
import { useTranslation } from 'react-i18next';
import { Events } from './Events';

export enum Module {
  BE = 'Backend API',
  ME = 'Measurement API',
  MA = 'Matching API',
  GU = 'Guidance',
  YU = '404 Not Found',
  GE = 'General Error',
}

export enum ErrorType {
  ST = 'Status Error',
  FR = 'Incorrect frame',
  CO = 'Corners not found',
  DB = 'Database Error',
  CA = 'Camera Error',
  R0 = '404 Not Found',
  VA = 'Measured Twice',
  NA = 'Not Available',
}

export enum Foot {
  RIGHT = 'Right',
  LEFT = 'Left',
  NA = 'NA',
}

export enum AgeGroup {
  ADULT = 'Adult',
  CHILD = 'Child',
  NA = 'NA',
}

// export enum Actions {
//   MeasureAgain = 'Measure Again',
//   RequestCameraPermission = 'Request Camera Permission',
//   LetUsKnow = 'Let us know',
//   TellMe = 'Tell Me',
//   BackToPartnerShop = 'Back to partner shop',
//   HelpCenter = 'Help Center',
//   BackToFootprint = 'Back to Footprint',
//   StartAgain = 'StartAgain',
//   NA = 'NA',
// }
export enum InfoBox {
  WithVideo = 'With Video',
  WithoutVideo = 'Without Video',
  NA = 'NA',
}

export class ErrorObject {
  module: Module
  shortCode: string
  fullErrorCode: string
  errorType: ErrorType
  errorCode: number
  foot: Foot
  ageGroup: AgeGroup
  action1: string = ''
  action2: string = ''
  infoBox: InfoBox = InfoBox.NA
  icon: string
  messageTitle: string
  messageBodyTransId: string
  messageInfoboxTransId: string
  messageFooter: string
  labelAction1: string
  labelAction2: string
  videoUrl: string
  isFooterVisible: boolean
  hasFooterActionMessage: boolean
  isTryToVisible: boolean
  isLongArrowVisible: boolean
  isFullSizeIcon: boolean

  centeredDesign: boolean

  constructor(
    module: Module,
    errorType: ErrorType,
    errorCode: number,
    foot: Foot,
    ageGroup: AgeGroup,
    shortCode: string,
    fullErrorCode: string
  ) {
    this.module = module
    this.errorType = errorType
    this.errorCode = errorCode
    this.foot = foot
    this.ageGroup = ageGroup
    this.shortCode = shortCode
    this.labelAction1 = ''
    this.labelAction2 = ''
    this.videoUrl = ''
    this.messageInfoboxTransId = ''
    this.isFooterVisible = false
    this.isTryToVisible = false
    this.isLongArrowVisible = false
    this.isFullSizeIcon = false
    this.centeredDesign = true
    this.fullErrorCode = fullErrorCode
    this.hasFooterActionMessage = true
    this.icon = ''
    const { t } = useTranslation()

    this.messageTitle = `cc.error.page-${shortCode}-00-title`
    this.messageBodyTransId = `cc.error.page-${shortCode}-00-body`
    this.messageFooter = t(`cc.error.page-${shortCode}-00-footer`)
  }
}

export class ErrorCodeParser {
  public static parseErrorCode(errorCode: string | undefined): ErrorObject {
    if (errorCode === undefined) {
      throw new Error('Invalid error code')
    }
    const { t } = useTranslation()

    errorCode = errorCode.replace('-', '').replace('.', '')
    let code2 = ''
    let code4 = ''

    if (errorCode.length < 4) {
      code2 = 'GE'
      code4 = 'NA'
    } else {
      code2 = errorCode.substring(0, 2)
      code4 = errorCode.substring(2, 4)
    }

    const moduleString = code2
    const module = this.parseModule(moduleString)

    const errorTypeString = code4
    const errorType = this.parseErrorType(errorTypeString)

    //handle 404 not found error
    if (module === Module.YU && errorType === ErrorType.R0) {
      let errorObject404 = new ErrorObject(module, errorType, 404, Foot.NA, AgeGroup.NA, errorCode.substring(0, 5), errorCode)
      errorObject404.labelAction1 = t(`cc.error.page-action-go-to-footprint`)
      errorObject404.action1 = Events.onBackToFootprintPress;
      errorObject404.icon = YURI
      errorObject404.isFullSizeIcon = true
      return errorObject404
    }

    const errorCodeString = errorCode.substring(4, 5)
    const errorCodeNumber = Number(errorCodeString)

    const footString = errorCode.substring(5, 6)
    const foot = this.parseFoot(footString)

    const ageString = errorCode.substring(6, 7)
    const ageGroup = this.parseAge(ageString)

    const errorObject = new ErrorObject(module, errorType, errorCodeNumber, foot, ageGroup, errorCode.substring(0, 5), errorCode)

    switch (errorObject.shortCode) {
      case 'GUCA1':
        errorObject.icon = GUCA1
        errorObject.isFooterVisible = true
        errorObject.action1 = ''
        errorObject.action2 = Events.onHelpCenterPress
        errorObject.labelAction2 = t(`cc.error.page-action-help-center`)
        errorObject.messageFooter = t(`cc.error.page-${errorObject.shortCode}-00-footer`)
        break
      case 'GUCA2':
        errorObject.icon = GUCA2
        errorObject.isFooterVisible = true
        errorObject.action1 = Events.onCameraPermissionRequest
        errorObject.labelAction1 = t(`cc.error.page-action-start-again`)
        errorObject.action2 = Events.onBackToShopPress
        errorObject.labelAction2 = t(`cc.error.page-action-back-to-partnershop`)
        errorObject.hasFooterActionMessage = false

        break
      case 'GUCA3':
        errorObject.icon = GUCA3
        errorObject.action1 = Events.onCameraPermissionRequest
        errorObject.labelAction1 = t(`cc.error.page-action-start-again`)
        errorObject.action2 = Events.onBackToShopPress
        errorObject.labelAction2 = t(`cc.error.page-action-back-to-partnershop`)
        errorObject.hasFooterActionMessage = false
        break
      case 'MEST1':
        errorObject.icon = MEST1
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        break
      case 'MEST2':
        errorObject.icon = MEST2
        errorObject.centeredDesign = true
        errorObject.isLongArrowVisible = true
        errorObject.infoBox = InfoBox.WithoutVideo
        errorObject.messageInfoboxTransId = 'cc.error.page-MEST2-00-infobox'
        break
      case 'MEST3':
        errorObject.icon = MEFR0
        errorObject.centeredDesign = true
        errorObject.isTryToVisible = false
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        break
      case 'MEST4':
        errorObject.icon = MEST4
        errorObject.centeredDesign = true
        errorObject.isTryToVisible = false
        errorObject.isFullSizeIcon = false
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        break
      case 'MEST5':
        errorObject.icon = MEST5
        errorObject.centeredDesign = true
        errorObject.isTryToVisible = false
        errorObject.isFullSizeIcon = false
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        break
      case 'MECO1':
        errorObject.messageTitle = t(`cc.error.page-${errorObject.shortCode}-${foot == Foot.LEFT ? 'left' : 'right'}-title`)
        errorObject.icon = MECO1
        errorObject.centeredDesign = false
        errorObject.isTryToVisible = true
        errorObject.infoBox = InfoBox.WithoutVideo
        errorObject.messageInfoboxTransId = 'cc.error.page-MECO1-00-infobox'
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        break
      case 'MECO2':
        errorObject.icon = MECO2
        errorObject.centeredDesign = false
        errorObject.isTryToVisible = true
        errorObject.infoBox = InfoBox.WithoutVideo
        errorObject.messageInfoboxTransId = 'cc.error.page-MECO2-00-infobox'
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        break
      case 'MEFR0':
        errorObject.messageTitle = t(`cc.error.page-${errorObject.shortCode}-${foot == Foot.LEFT ? 'left' : 'right'}-title`)
        errorObject.videoUrl = `tut_for_error_page_${ageGroup == AgeGroup.ADULT ? 'adult' : 'child'}_${
          foot == Foot.LEFT ? 'left' : 'right'
        }.mp4`
        errorObject.icon = MEFR0
        errorObject.centeredDesign = false
        errorObject.isTryToVisible = true
        errorObject.infoBox = InfoBox.WithVideo
        errorObject.messageInfoboxTransId = 'cc.error.page-MEFR0-00-infobox'
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        break
      case 'MAST1':
        errorObject.icon = MAST1
        errorObject.centeredDesign = false
        errorObject.infoBox = InfoBox.WithoutVideo
        errorObject.messageInfoboxTransId = 'cc.error.page-MAST1-00-infobox'
        errorObject.action1 = Events.onLetUsKnowPress
        errorObject.labelAction1 = t(`cc.error.page-action-let-us-know`)
        errorObject.isFooterVisible = true
        errorObject.action2 = Events.onBackToShopPress
        errorObject.labelAction2 = t(`cc.error.page-action-back-to-partnershop`)
        errorObject.messageFooter = t(`cc.error.page-${errorObject.shortCode}-00-footer`)
        break
      case 'MAST2':
        errorObject.icon = MAST2
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        errorObject.isFooterVisible = true
        errorObject.action2 = Events.onBackToShopPress
        errorObject.labelAction2 = t(`cc.error.page-action-back-to-partnershop`)
        errorObject.messageFooter = t(`cc.error.page-${errorObject.shortCode}-00-footer`)
        break
      case 'MADB1':
        errorObject.icon = MADB1
        errorObject.isFooterVisible = true
        errorObject.action1 = Events.onBackToShopPress
        errorObject.labelAction1 = t(`cc.error.page-action-back-to-partnershop`)
        errorObject.action2 = Events.onMeasureAgainPress  
        errorObject.labelAction2 = t(`cc.error.page-action-measure-again`)
 	errorObject.hasFooterActionMessage = false
        break
      case 'MADB2':
        errorObject.icon = MADB2
        errorObject.action1 = Events.onBackToShopPress
        errorObject.infoBox = InfoBox.NA
        errorObject.labelAction1 = t(`cc.error.page-action-back-to-partnershop`)
        break
      case 'MAVA1':
        errorObject.icon = MAVA1
        errorObject.action1 = Events.onMeasureAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-measure-again`)
        errorObject.isFooterVisible = false
        errorObject.messageFooter = t(`cc.error.page-${errorObject.shortCode}-00-footer`)
        break
      case 'WDAR1':
        errorObject.icon = MADB2
        errorObject.action1 = Events.onBackToShopPress
        errorObject.labelAction1 = t(`cc.error.page-action-back-to-partnershop`)
        errorObject.infoBox = InfoBox.NA
        errorObject.hasFooterActionMessage = false
        errorObject.isFooterVisible = false
        break
      case 'BEDB1':
        errorObject.icon = MADB2
        errorObject.action1 = Events.onBackToShopPress
        errorObject.labelAction1 = t(`cc.error.page-action-back-to-partnershop`)
        errorObject.infoBox = InfoBox.NA
        errorObject.hasFooterActionMessage = false
        errorObject.isFooterVisible = false
        break
      default:
        errorObject.messageTitle = `cc.error.page-GEN01-00-title`
        errorObject.messageBodyTransId = `cc.error.page-GEN01-00-body`
        errorObject.icon = GUCA3
        errorObject.action1 = Events.onStartAgainPress
        errorObject.labelAction1 = t(`cc.error.page-action-start-again`)
        errorObject.action2 = Events.onBackToShopPress
        errorObject.infoBox = InfoBox.NA
        errorObject.fullErrorCode = errorCode
        errorObject.isFooterVisible = false
    }
    return errorObject
  }

  private static parseModule(moduleString: string): Module {
    switch (moduleString) {
      case 'BE':
        return Module.BE
      case 'ME':
        return Module.ME
      case 'MA':
        return Module.MA
      case 'GU':
        return Module.GU
      case 'YU':
        return Module.YU
      default:
        return Module.GE
    }
  }

  private static parseErrorType(errorTypeString: string): ErrorType {
    switch (errorTypeString) {
      case 'ST':
        return ErrorType.ST
      case 'FR':
        return ErrorType.FR
      case 'CO':
        return ErrorType.CO
      case 'DB':
        return ErrorType.DB
      case 'CA':
        return ErrorType.CA
      case 'CA':
        return ErrorType.CA
      case 'R0':
        return ErrorType.R0
      default:
        return ErrorType.NA
    }
  }

  private static parseFoot(footString: string): Foot {
    switch (footString) {
      case 'R':
        return Foot.RIGHT
      case 'L':
        return Foot.LEFT
      default:
        return Foot.NA
    }
  }
  private static parseAge(ageString: string): AgeGroup {
    switch (ageString) {
      case 'A':
        return AgeGroup.ADULT
      case 'C':
        return AgeGroup.CHILD
      default:
        return AgeGroup.NA
    }
  }
}
