import {
  ArticleData,
  CONVERSION_ACTION,
  CurrencyISO4217,
  Matching,
  ShopSettings,
} from '../interfaces/ProductService.interface';
import shopService from '../services/ShopService';
import articleService from '../services/ArticleService';

const findMatching = (article: ArticleData, matching: Matching) => {

  if (matching.article_number == article.number) {
    return Promise.resolve(matching);
  }

  return shopService.processMeasurement(matching.measurement_id, article);
}

const createConversion = (
  matching: Matching,
  article: ArticleData,
  settings: ShopSettings) => {
    return shopService.createConversion(
      matching, article, CONVERSION_ACTION.MATCHING_SECONDARY, settings);
};

export class ShopWidgetService {

  private gtin: string;

  private ean: string;

  private articleNumber: string;

  private articleName: string;

  private articlePrice: number;

  private articleImage: string;

  private currency: CurrencyISO4217;

  constructor(article: ArticleData) {
    this.gtin = article.gtin;
    this.ean = article.ean;
    this.articleNumber = article.number;
    this.articleName = article.name;
    this.articlePrice = article.price;
    this.articleImage = article.image;
    this.currency = article.currency ?
      CurrencyISO4217[article.currency] : CurrencyISO4217.EUR;

  }

  getArticleData(): ArticleData {
    return {
      id: this.articleNumber,
      gtin: this.gtin,
      ean: this.ean,
      number: this.articleNumber,
      name: this.articleName,
      price: this.articlePrice,
      image: this.articleImage,
      currency: this.currency,
      sizes: []
    }
  }

  handleMatching(matching: Matching, settings: ShopSettings) {

    const article = this.getArticleData();
    console.log('[driver] Widget handling matching', article);

    let outcome = Promise.resolve();

    if (this.gtin && !this.articleNumber) {
      outcome = this.findArticleByGtin()
        .then(result => {
        article.number = result.article_number;
      });
    }

    return outcome.then(() => findMatching(article, matching))
      .then(result => {
        // multiwidget support
        // When we receive a match event from another widget
        // a secondary match is created for every widget present in
        // the page
        if (matching.article_number !== article.number) {
          createConversion(result, article, settings);
        }
      });

  }

  findArticleByGtin() {
    return articleService.lookupArticle({
      gtin: this.gtin,
      articleName: '',
      articleNumber: ''
    })
    .then(result => {

      if (!result.data || result.data.length === 0) {
        console.warn(`[driver] Article with gtin ${this.gtin} not found`);
        return null;
      }

      return result.data[0];
    });
  }

  setArticleNumber(artNum: string) {
    this.articleNumber = artNum;
  }


}
