import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DarkSlateBlue } from '../../../../theme/colors';
import { close } from '../../../../assets';
import Events from '../Events';
import GlobalStyle from '../../../../assets/styles/global';

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  padding: 16px 49px;
  color: ${DarkSlateBlue};
  background: linear-gradient(180deg, rgba(224, 236, 248, 1) 0%, rgba(253, 245, 236, 1) 100%);
  align-items: center;
  justify-content: center;

  &.fpt-error {
    justify-content: start;
    padding-top: 30%;
    @media (max-width: 380px) {
      padding-top: 10%;
    }
  }
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 16px;
  right: 49px;
`;

const Wrapper = styled.div`
  height: 100%;
  position: absolute;
  top: 16px;
  transform: translate3d(0,0,0);
`;

export const Fullscreen: React.FunctionComponent<any> = (props) => {
  const { emitter, children, className } = props;

  const handleResize = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    console.log('resize', vh);
  };

  const handleClose = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    emitter.emit(Events.onCloseModalPress);
  }

  useEffect(() => {

    // We listen to the resize event
    window.addEventListener('resize', handleResize);

    // The cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
      <Screen className={className}>
        <GlobalStyle />
        <Wrapper>
          { children }
          <CloseIcon src={close} onClick={handleClose}/>
        </Wrapper>
      </Screen>
  )
};
