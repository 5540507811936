import axios, { AxiosInstance } from 'axios';
import { ShopSettings } from '../interfaces/ProductService.interface';

class ShopSettingsService {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.API_URL,
    });
  }

  setApiKey(apiKey: string) {
    this.client.defaults.headers.common['X-API-KEY'] = apiKey;
  }

  async getSettings(): Promise<ShopSettings> {
    const domain = window.location.hostname;
    const result = await this.client.get(`shops/settings?domain=${domain}`);
    return result.data[0];
  }

}

export default new ShopSettingsService();
