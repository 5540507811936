import React, { useContext } from 'react';
import EventEmitter from 'eventemitter3';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components'
import {
  MultiRecommendation,
} from '../../interfaces/ProductService.interface';
import { Button } from '../Common/Button';
import { DarkSlateBlue } from '../../theme/colors';
import RecommendationEvents from './Events';


const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Panel = styled.div`
  position: relative;
  padding: 20px;
  margin: 193px 26px;
  object-fit: contain;
  text-align: center;
  color: var(--dark-slate-blue);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius:20px;
  background-color: #f8fcfd;
  width: 100%;
`;

const Text = styled.p`
  line-height: 16px;
  font-size: 14px;
  font-family: 'Inter500';
  color: ${DarkSlateBlue};
  `;

interface ConfirmDialogProps {
  emitter: EventEmitter;
  recommendation: MultiRecommendation;
}

export function ConfirmDialogComponent(props: ConfirmDialogProps) {

  const {
    recommendation,
    emitter,
  } = props;

  const handleConfirm = () => emitter.emit(
    RecommendationEvents.onFeedbackConfirm, { confirm:true, recommendation });

  const handleCancel = () => emitter.emit(
    RecommendationEvents.onFeedbackConfirm, { confirm:false, recommendation });


  return (
    <ParentContainer>
      <Panel>
        <Text>
          <FormattedMessage id="drv.feedback-confirm-dialog.text"></FormattedMessage>
        </Text>
        <Button type="button" className="wide-primary" onClick={handleConfirm}>
          <FormattedMessage id="drv.feedback-confirm-dialog.confirm"></FormattedMessage>
        </Button>
        <Button type="button" className="wide-secondary" onClick={handleCancel}>
          <FormattedMessage id="drv.feedback-confirm-dialog.cancel"></FormattedMessage>
        </Button>
      </Panel>
    </ParentContainer>
  );
}
