import React from 'react';
import styled from 'styled-components';
import { CDN_ASSET_URL } from '../../services/AssetsService';


const Panel = styled.div`
  flex: initial;
  padding: 20px 0;

  & img {
    margin: 0 auto 12px;
    text-align: center;
  }
`;

const ShopLogo = styled.img.attrs((props) => ({
  src: props.theme.shopLogo,
}))`
  display: block;
  max-height: 6vh;
  max-width: 40vw;
  min-height: 25px;
  margin-bottom: 12px;
`;

const FPTLogo = styled.img`
  display: block;
  max-height: 5vh;
  min-height: 25px;
  margin: 4vh auto 12px;
`;

const AND = styled.img`
  display: ${(props) => (props.theme.shopLogo ? 'block' : 'none')};
  max-width: 12px;
  min-height: 10px;
  margin-bottom: 12px;
`;

const LogoPanel = function () {
  return (
    <>
        <FPTLogo src={CDN_ASSET_URL + '/logos/fpt_logo_splashscreen.png'} />
        <AND src={CDN_ASSET_URL + '/logos/X@3x.png'} />
        <ShopLogo />
    </>
  )
}

export default LogoPanel
