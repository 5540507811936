import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import sessionService from '../../services/SessionService';
import { Button } from '../Common/Button';
import EventEmitter from 'eventemitter3';
import Events from './Events';
import { Fullscreen, ContentPanel, Footer, Section } from '../Common/Modal/Fullscreen';
import LogoPanel from './LogoPanel';
import {
  FUNNEL,
} from '../../interfaces/ProductService.interface';
import { i18nConfig } from '../../assets';
import { useIntl } from 'react-intl';
import { ShopSettingsContext } from '../../FootprintTech/AppController';

interface SplashscreenProps {
  emitter: EventEmitter;
  funnel: FUNNEL;
}

const Welcome = styled.div`
  font: 14px Inter600, sans-serif;
  margin-bottom: 18px;
  margin-top: 3vh;
`;

const Text = styled.div`
  font: bold 3vh/1.36 Inter700, sans-serif;
  margin-bottom: 25px;
`;
 
const CustomSelect = styled.select`
  font-family: 'Inter500';
  font-size: 16px !important;
  padding: 0 !important;
  background-color: #fff !important;
  background-image: none !important;
  
  &>option {
    font-family: 'Inter500';
  }
`;


const Splashscreen: React.FunctionComponent<SplashscreenProps> = (props) => {
  const { emitter, funnel } = props;
  const intl = useIntl();
  const shopSettings = useContext(ShopSettingsContext);

  const handlePrimary = (ev) => {

    // This is to prevent banner from handling click event
    ev.nativeEvent.stopImmediatePropagation();

    if (funnel === FUNNEL.UNDEFINED || funnel === FUNNEL.SELF) {
      console.log('[driver] SELF funnel selected');
      return emitter.emit(Events.onFunnelSelect, 'SELF');
    }

    console.log('[driver] OTHER funnel selected');
    emitter.emit(Events.onFunnelSelect, 'OTHER');
  };

  const handleSecondary = (ev) => {

    // This is to prevent banner from handling click event
    ev.nativeEvent.stopImmediatePropagation();

    console.log('[driver] Handle secondary');
    emitter.emit(Events.onFunnelSelect, 'OTHER');
  };

  const handleResize = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    console.log('resize', vh);
  };

  const handleLanguageSelect = ev => {
    ev.nativeEvent.stopImmediatePropagation();
    const selectedLang = ev.target.value;
    console.log(`[driver] Language ${selectedLang} selected in Splashscreen`, ev.target);
    emitter.emit(Events.onLanguageSelect, selectedLang);
  };

  useEffect(() => {

    sessionService.updateExitCode('PDP', 'splashscreen.displayed');
    // We listen to the resize event
    window.addEventListener('resize', handleResize)

    // The cleanup function
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  return (
    <Fullscreen emitter={emitter}>
      <LogoPanel />
      <ContentPanel>
        <Welcome>
          <FormattedMessage id="drv.splashscreen.welcome" />
        </Welcome>
        <Text>
          <FormattedMessage id="drv.splashscreen.text" />
        </Text>
        <Button onClick={handlePrimary} type="button" className="wide-primary">
          <FormattedMessage id="drv.splashscreen.option.adult" />
        </Button>
        {funnel === FUNNEL.UNDEFINED &&
          <Button onClick={handleSecondary} type="button" className="wide-secondary">
            <FormattedMessage id="drv.splashscreen.option.child" />
          </Button>
        }
      { shopSettings.languageSelectEnabled &&
        <CustomSelect onChange={handleLanguageSelect} value={intl.locale}>{
          Object.keys(i18nConfig).map(key => <option key={key} value={key}>{i18nConfig[key].name}</option>)
        }</CustomSelect>
      }
      </ContentPanel>
      <Footer>
        <Section>
          <FormattedMessage id="drv.splashscreen.footer"
            values={{
              policy: (chunks) => <a href="https://footprinttech.de/privacy-policy-webapp/" target="_blank">{chunks}</a>,
              tos: (chunks) => <a href="https://footprinttech.de/terms-of-use-webapp/" target="_blank">{chunks}</a>
            }} />
        </Section>
        <Section>
          <FormattedMessage id="drv.splashscreen.imprint"
            values={{
              imp: (chunks) => <a href="https://footprinttech.de/imprint-webapp/" target="_blank">{chunks}</a>,
            }} />
        </Section>
      </Footer>
    </Fullscreen>
  )
};

export default Splashscreen;
