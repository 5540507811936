import React, { useContext } from 'react';
import { ArticleInfo } from '../Common/ArticleInfo';
import styled from 'styled-components'
import { UserOptions } from './UserOptions';
import { RecommendationPanel } from './RecommendationPanel';
import checkoutService from './CheckoutService';
import { CheckoutHeader } from '../Common/CheckoutHeader';
import { ArticleContext, ShopSettingsContext } from '../../FootprintTech/AppController';
import {
  MultiRecommendation,
} from '../../interfaces/ProductService.interface';
import EventEmitter from 'eventemitter3';

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

interface RecommendationProps {
  emitter: EventEmitter;
  recommendation: MultiRecommendation;
}

export function MultiRecommendationComponent(props: RecommendationProps) {

  const {
    recommendation,
    emitter,
  } = props;

  const shopSettings = useContext(ShopSettingsContext);

  const listItems = recommendation.variants.map(item => {
    const sizeNumber = item.size.value;
    const sizeDescription = item.size.description;
    return (
      <li>
          <h2>{item.variant_name}:</h2>
          <p>
            { sizeNumber + ' ' + sizeDescription }
          </p>
    </li>);
  }
  );


  return (
    <ParentContainer>
      <RecommendationPanel>
        <ul>{listItems}</ul>
      </RecommendationPanel>
      </ParentContainer>
  );
}
