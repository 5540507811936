import axios, { AxiosResponse } from 'axios';
export const CDN_ASSET_URL = 'https://cdn.footprinttech.de/assets';

const client = axios.create({
  baseURL: CDN_ASSET_URL,
});

export const AssetsService = {
  fetchLanguageFile(lang: string) {
    return client.get(`locales/${lang}/messages.json`)
      .then((result: AxiosResponse) => result.data);
  },
  fetchTheme(themeName: string) {
    return client.get(`themes/${themeName}.json`)
      .then((result: AxiosResponse) => result.data);
  }
};
