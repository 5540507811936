import {
  ArticleData,
  ArticleResource,
  Matching,
  MeasurementProcess,
  MultiRecommendation,
  ProductServiceInterface,
  ShopSettings,
  FLOW_END_MAP,
  CONVERSION_ACTION,
  SHOP_PLATFORM,
} from '../interfaces/ProductService.interface';
import RenderUtil from '../util/RenderUtil';
import articleService from './ArticleService';
import sessionService from './SessionService';
import storageService from './StorageService';
import conversionsService from './ConversionsService';
import matchingService from './MatchingService';
import recommendationService from './RecommendationService';
import shopSettingsService from './ShopSettingsService';
import { PollingState } from '../components/QRGateway';
import { DefaultError } from '../error/DefaultError';
import clientEventsService from './ClientEventsService';
import { ShopifyHelper } from './ShopifyHelper';


let customShopService: ProductServiceInterface;

function hasArticle(data) {
  return data && data.length > 0;
}

function getSessionLastMeasurement() {
  return sessionService.getSessionMeasurements()
    .then(result => {
      if (result && result.length > 0) {
        return result[0];
      }
      return null;
    });
}

function checkMeasurementState() {
  return sessionService.getSessionEvents()
    .then(events => {
      // Check if session CreatedAt is older than 30 minutes

      const session = events.pop();

      const createdAt = new Date(session.createdAt);
      const now = new Date();
      const diff = now.getTime() - createdAt.getTime();
      const minutes = Math.floor(diff / 1000 / 60);

      if (minutes > 5) {
        console.log('[driver] polling session expired');
        return PollingState.TIMEOUT;
      }

      if (events.length === 0 ||
         (events.length > 0 && events[0].event_name === 'shop.qr.displayed')) {
        return PollingState.UNDEFINED;
      }

      const initialValue = PollingState.MEASURING;

      return events.reduce(
        (accumulator: PollingState, currentValue) => {
          if (accumulator === PollingState.DONE ||
              accumulator === PollingState.CANCELLED) {
            return accumulator;
          }

          if (currentValue.event_name === 'con.measurement.success') {
            return PollingState.DONE;
          }

          if (currentValue.event_name === 'shop.qr.closed') {
            return PollingState.CANCELLED;
          }

          return accumulator;

        }, initialValue,
      );

    });
}

function waitForPollingOutcome(expected: PollingState) {
  return new Promise((fulfill, reject) => {

    let toId: string | number | NodeJS.Timeout;

    const poll = () => {
      console.log('[driver] polling . . .');
      checkMeasurementState()
        .then((outcome:PollingState) => {

          console.log('[driver] Polling: ', outcome);

          // setPollingState(outcome);
          const isFinalOutcome =
            outcome === PollingState.DONE ||
            outcome === PollingState.CANCELLED ||
            outcome === PollingState.TIMEOUT;

          const continuePolling = !isFinalOutcome;

          if (outcome === expected) {
            console.log('[driver] stopped polling');
            clearTimeout(toId);
            fulfill(outcome);
          }

          if (continuePolling) {
            console.log('[driver] waiting for user measurement');
            toId = setTimeout(poll, 1000);
          } else if (outcome === PollingState.DONE) {
            console.log('[driver] stopped polling');
            clearTimeout(toId);
            fulfill(outcome);
          } else {
            console.log('[driver] stopped polling');
            clearTimeout(toId);
            reject(outcome);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }

    console.log('[driver] start polling');
    poll();
  });
}

let shopSettings: ShopSettings = null;

const ShopService =  {

  // FIXME: Temporary support for legacy custom service
  setCustomService(service: ProductServiceInterface) {
    customShopService = service;
  },

  setApiKey(apiKey:string) {
    sessionService.setApiKey(apiKey);
    conversionsService.setApiKey(apiKey);
    shopSettingsService.setApiKey(apiKey);
  },

  setShopSettings(settings:ShopSettings) {
    shopSettings = settings;
  },

  getArticleData(fptEl:HTMLElement): ArticleData {

    // Exception for Footprint live services (deprecated)
    const { hostname } = window.location;
    if (
      hostname === 'shopware6.podolino.de' ||
      hostname === 'lidl.footprinttech.de' ||
      hostname === 'defense.footprinttech.de'
    ) {
      return customShopService.getArticleData();
    }

    const article: ArticleData = {
      id: '',
      gtin: '',
      ean: '',
      number: '',
      sizes: [],
    };

    console.log('[driver] getting article data', fptEl.dataset);

    if ('articleNumber' in fptEl.dataset) {
      console.log('[driver] got article number', fptEl.dataset.articleNumber);
      article.number = fptEl.dataset.articleNumber;
      article.id = article.number;
    } else if ('gtin' in fptEl.dataset) {
      console.log('[driver] got article gtin', fptEl.dataset.gtin);
      article.gtin = fptEl.dataset.gtin;
    } else if ('ean' in fptEl.dataset) {
      console.log('[driver] got article ean', fptEl.dataset.ean);
      article.ean = fptEl.dataset.ean;
    } else if (customShopService) {
      return customShopService.getArticleData();
    }

    if ('articleName' in fptEl.dataset) {
      console.log('[driver] got article name', fptEl.dataset.articleName);
      article.name = fptEl.dataset.articleName;
    }



    if ('articleImage' in fptEl.dataset) {
      console.log('[driver] got article image', fptEl.dataset.articleImage);
      article.image = fptEl.dataset.articleImage;
    }

    if ('articlePrice' in fptEl.dataset) {
      console.log('[driver] got article price', fptEl.dataset.articlePrice);
      const str = fptEl.dataset.articlePrice;
      const price = Number(str);
      article.price = Number.isNaN(price) || str === '' ? null : price;
      console.log(`[driver] parsed article price ${article.price}`);
    }

    if ('currency' in fptEl.dataset) {
      console.log('[driver] got currency', fptEl.dataset.currency);
      article.currency = fptEl.dataset.currency;
    }

    if (customShopService) {
      const customArticle = customShopService.getArticleData();
      article.sizes = customArticle.sizes || [];
    }

    return article;
  },

  getApiKey(config?:any): string {

    // Exception for Footprint live services (deprecated)
    const { hostname } = window.location;
    if (
      hostname === 'shopware6.podolino.de' ||
      hostname === 'lidl.footprinttech.de' ||
      hostname === 'defense.footprinttech.de'
    ) {
      return customShopService.getApiKey();
    }

    if (config.apiKey) {
      return config.apiKey;
    }

    const fptEl = document.querySelector('#footprinttech-button') as HTMLElement;

    if (fptEl && 'apiKey' in fptEl.dataset) {
      console.log('[driver] got API key', fptEl.dataset.apiKey);
      return fptEl.dataset.apiKey;
    } else if (customShopService) {
      return customShopService.getApiKey();
    }

    return '';
  },

  getClientSessionId(): string {

    const fptEl = RenderUtil.getFPTTag();

    if (fptEl && 'sessionId' in fptEl.dataset) {
      console.log('[driver] got client session id', fptEl.dataset.sessionId);
      return fptEl.dataset.sessionId;
    } else if (customShopService) {
      return customShopService.getClientSessionId();
    }

    return '';
  },

  updateSessionId(sessionId: string) {
    storageService.update('sessionId', sessionId);
    articleService.setSessionId(sessionId);
    sessionService.setSessionId(sessionId);
    clientEventsService.setSessionId(sessionId);
    matchingService.setSessionId(sessionId);
    return sessionId;
  },

  createSession(): Promise<any> {
    const anonymousId = storageService.getAnonymousId();

    const sessionData = {
      screen_height: window.innerHeight,
      screen_width: window.innerWidth,
      analytics_accepted: true,
      client_session_id: this.getClientSessionId(),
      anonymous_id: anonymousId,
      referrer: window.location.href,
    };

    return sessionService.createSession(sessionData);
  },

  getTarget(): string {
    return process.env.FPT_APP_URL;
  },

  handleCtaButtonClick(article:ArticleData): Promise<any> {

    storageService.addViewedTooltip('drv.cta-button.tooltip.text');
    return this.createSession()
      .then(result => {
        this.updateSessionId(result);
        return this.checkIfArticleExists(article);
      });
  },

  checkIfArticleExists(article:ArticleData): Promise<ArticleResource> {
    console.log('[driver] checking if article exists');

    const articleData = {
      articleNumber: article.number,
      articleName: article.name,
      gtin: article.gtin || article.ean,
    };

    return articleService
      .lookupArticle(articleData)
      .then((result) => {
        const { data } = result;

        console.log('[driver] lookup article response', result);

        if (hasArticle(data)) {
          return data[0];
        }

        throw new DefaultError('WDAR1', 'Article not found');
      });
  },

  getQRData() {
    console.log('[driver] displaying desktop widget with settings', shopSettings);
    const { funnel, lang, theme, steps, desktopFlowEnd, languageSelectEnabled } = shopSettings;
    const themeName = theme ? theme.name : 'default';
    const selectedFunnel = funnel || 'UNDEFINED';
    const intermediateSteps = steps || 'DEFAULT';
    const end = desktopFlowEnd && FLOW_END_MAP[desktopFlowEnd] ? FLOW_END_MAP[desktopFlowEnd] : 'PDP';
    console.log('[driver] displaying desktop widget with end', end);

    const params: MeasurementProcess = {
      theme: themeName,
      flow: `${selectedFunnel}-${intermediateSteps}-${end}`,
      session: storageService.getSessionId(),
      end,
      gdpr: 1,
    };

    if (lang) {
      params.lang = lang;
    }

    if (languageSelectEnabled) {
      params['lang-select'] = 1;
    }

    return params;

  },

  isSuccessfulMatching(m:Matching) {
    return m.error_code === 'MAST0-00';
  },

  handleFunnelSelect(funnel: string, article: ArticleData) {
    console.log(`[driver] handling guest with funnel ${funnel}`);
    const target = this.getTarget();
    const { steps, lang, theme, languageSelectEnabled } = shopSettings;
    const themeName = theme ? theme.name : 'default';
    const end = 'PDP';

    const params: MeasurementProcess = {
      articleNumber: article?.number,
      theme: themeName,
      flow: '',
      steps: steps || 'DEFAULT',
      session: storageService.getSessionId(),
      funnel,
      gdpr: 1,
      end,
    };

    if (lang) {
      params.lang = lang;
    }

    if (languageSelectEnabled) {
      params['lang-select'] = 1;
    }

    return this.openFptApp(target, params);
  },

  processMeasurement(measurementId: string, article: ArticleData) {
    storageService.update('measurementId', measurementId);
    storageService.clearMatchings();
    return this.handleExistingMeasurement(measurementId, article);
  },

  handleMeasuringResult(data, article: ArticleData) {

    window.focus();
    console.log('[driver] received data', data);

    return new Promise((fulfill, reject) => {

      console.log('[driver] handle measuring result callback called', data.result);

      if (data.result && data.result.error && data.result.error === 'app-closed') {
        console.log('[driver] App closed by user');
        // FIXME: We need a new error code for this scenario
        reject(new DefaultError('WDCNV1', 'App closed by user'));
        return;
      }

      if (data.result && data.result.measurementId) {
        const { sessionId, measurementId } = data.result;
        this.updateSessionId(sessionId);
        const result = this.processMeasurement(measurementId, article);
        fulfill(result);
        return;
      }

      console.warn('[driver] Could not retrieve measurement data', data);
      // reject(new DefaultError('GEN01-00', 'Could not retrieve measurement data'));

    });
  },

  openFptApp(target: string, processData: MeasurementProcess) {
    const { funnel, steps, end } = processData;
    const intermediateSteps = steps || 'DEFAULT';

    return RenderUtil.openFptApp(target, {
      theme: processData.theme,
      flow: `${funnel}-${intermediateSteps}-${end}`,
      session: storageService.getSessionId(),
      lang: processData.lang,
      gdpr: processData.gdpr,
      'lang-select': processData['lang-select'],
    });
  },

  createConversion(
    matching: Matching,
    article: ArticleData,
    action: CONVERSION_ACTION,
  ) {

    if (matching.error_code !== 'MAST0-00') {
      return;
    }

    const price = RenderUtil.parseArticlePrice(article.price, shopSettings);

    const data: any = {
      matching_id: matching.matching_id,
      price: price,
      quantity: 1,
      tax: 0,
      total: price,
      currency: article.currency,
      action,
    };

    const clientSessionId = this.getClientSessionId();

    if (clientSessionId) {
      data.client_session_id = clientSessionId;
    }

    conversionsService.postConversion(data);
  },

  createRecommendationConversions(
    recommendation: MultiRecommendation,
    reference: string
  ) {
    const matchings = recommendation.variants
      .filter(item => item.error_code==='MAST0-00')
      .map(item => item.matching_id);

    const payload = {
      session_id: recommendation.session_id,
      customer_reference: reference,
      action: CONVERSION_ACTION.MATCHING_PRIMARY,
      matchings,
    };

    conversionsService.postConversion(payload);
  },

  readClientCookie(key) {
    let cookie = document.cookie.split(';').find((item) => item.includes(key));
    if (cookie?.indexOf('=') > -1) {
      return cookie.split('=')[1];
    }
    return null;
  },

  handleMeasurementDone(article: ArticleData) {

    return getSessionLastMeasurement()
      .then(result => {

        if (!result) {
          // FIXME: We need a new error code for this scenario
          throw new DefaultError('GEN01-00', 'Current session has no measurement');
        }

        const resultData = {
            result: {
              measurementId: result.measurement_id,
              sessionId: result.session_id,
            }
        };

        return this.handleMeasuringResult(resultData, article);
      });

  },

  async handleExistingMeasurement(measurementId: string, article: ArticleData) {

    // create session if it does notexist
    let sessionId = storageService.getSessionId();

    if (!sessionId) {
      sessionId = await this.createSession();
      this.updateSessionId(sessionId);
    }

    const { multiRecommendationsEnabled, articleTags } = shopSettings;

    if (multiRecommendationsEnabled && articleTags) {
      recommendationService.setSessionId(sessionId);
      recommendationService.setMeasurementId(measurementId);
      recommendationService.setTags(articleTags);
      return recommendationService.createRecommendation()
        .then(result => result.data);
    }

    const previousMatch = storageService.findMatching(article.number);
    if (previousMatch) {
      previousMatch.isStoredInSession = true;
      return Promise.resolve(previousMatch);
    }

    matchingService.setSessionId(sessionId);
    matchingService.setMeasurementId(measurementId);
    matchingService.setArticleNumber(article.number);

    // create matching
    console.log('[driver] Creating matching for existing existing measurement');

    return matchingService.createMatching()
      .then(result => {
        const { data } = result;
        storageService.addMatching(data, article.number);

        return data;
      })
      .catch((err) => {

        if (err instanceof DefaultError) {
          throw err;
        }

        if (err.response && err.response.status == '401') {
          console.warn('[driver] soemething went wrong with getting a match',
            err.response.statusText);
          console.warn('[driver] Seems like Session has expired, creating new session');
          return this.createSession()
            .then(result => this.updateSessionId(result))
            .then(() => {
                return matchingService.createMatching();
            })
            .then(result => {
              const { data } = result;
              storageService.addMatching(data, article.number);
              return data;
            })
            .catch((err) => {
              throw new DefaultError(
                'GEN01-00',
                'Create new session or matching failed',
                { cause: err }
              );
            });
        } else if (
          err.response &&
          err.response.status == '409' &&
          err.response.data &&
          err.response.data.error_code == 'BEDB1-00') {
            throw new DefaultError(
              'BEDB1-00',
              'Article not found',
              { cause: err }
            );
        }

        throw new DefaultError(
          'GEN01-00',
          'Soemething went wrong',
          { cause: err }
        );
      });
  },

  handleAddToCart(matching: Matching, article: ArticleData) {

    if (customShopService) {
      console.log('[driver] custom service handling add to cart');
      customShopService.handleMatching(matching);
    } else if (shopSettings.platform === SHOP_PLATFORM.SHOPIFY) {
      console.log('[driver] Shopify generic service handling add to cart');
      ShopifyHelper.handleAddToCart(matching, shopSettings);
    } else {
      console.warn('[driver] Add to cart called but feature is not supported');
    }

    this.createConversion(matching, article, CONVERSION_ACTION.ADDITEM, shopSettings);
  },

  waitForUserToStartMeasuring() {
    storageService.clearMeasurement();
    storageService.clearMatchings();
    return waitForPollingOutcome(PollingState.MEASURING);
  },

  waitForUserToFinishMeasuring() {
    return waitForPollingOutcome(PollingState.DONE);
  },

  logError(err: DefaultError, article: ArticleData) {

    let cause = 'cause' in err ?
      (err.cause as Error).message : '';

    if (err.errorCode === 'WDAR1' || err.errorCode === 'MADB2-00') {
      cause = article.number;
    }

    clientEventsService.createEvent(
      `shop.error.${(err as DefaultError).errorCode}`,
      cause
    );
  },

  logRecommendationFeedback(recommendation:MultiRecommendation) {
    const clientEvents = recommendation
      .variants.filter(item => item.error_code==='MAST0-00')
      .map(item => {
        return {
          event_name: "shop.recommendation-feedback",
          event_details: item.size?.feedback,
          matching_id: item.matching_id,
        }
      });
    clientEventsService.createEventBatch(clientEvents);
  }
}

export default ShopService;
