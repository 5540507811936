import { ArticleData, ShopSettings } from '../interfaces/ProductService.interface';


export const defaultArticle: ArticleData = {
  id: 'test',
  number: '',
  name: 'Test Article',
  image: '',
  price: 199.99,
  sizes: ['42']
};

export const defaultShopSettings: ShopSettings = {
  domain: ''
};
