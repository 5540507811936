import React, { useContext } from 'react';
import { ShopSettingsContext } from '../../../FootprintTech/AppController';
import { IErrorPanel } from './Interface';
import { Fullscreen, ContentPanel, Footer, Section, FptLogo } from '../Modal/Fullscreen';
import { FormattedMessage } from 'react-intl';
import { Images} from '../../../assets/Images';
import styled from 'styled-components';
import { ErrorPanel } from '@footprint/common-components';
import RenderUtil from '../../../util/RenderUtil';
import { ModalEvents } from '../Modal';
import { i18n } from '@footprint/common-components';

export function ErrorPanelStandalone(props: IErrorPanel) {

  const OuterContainer = styled.div`
    width: 633px;
    padding: 25px;
    border-radius: 30px;
    background-color: #f8fcfd;
    margin: auto;
  `;
  const InnerContainer = styled.div`
    padding-bottom: 10px;
    border-radius: 20px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
    background-color: #fff;
    margin: auto;
  `;
  const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    color: var(--dark-slate-blue);
    cursor: pointer;
    width: 46px;
  `;
  const CloseBut = styled.img``;
  const ContentContainer = styled.div`
    padding: 10px;
    text-align: left;
    position: relative;
  `;
  const {
    error,
    emitter,
  } = props;

  let isMobile = RenderUtil.isMobile();
  const shopSettings = useContext(ShopSettingsContext);
  const lang = shopSettings.lang || RenderUtil.getLang();
  i18n.changeLanguage(lang);

  if (isMobile) {
    return (
    <Fullscreen emitter={emitter} className="fpt-error">
      <ContentPanel>
          <ErrorPanel code={error.errorCode} source='PDP' hideAction={false} emitter={emitter} showFooter={false}></ErrorPanel>
      </ContentPanel>
      <Footer>
        <FptLogo />
        <Section>
          <a href="https://footprinttech.de/privacy-policy-webapp/" target="_blank">
            <FormattedMessage id="common.privacy-policy" />
          </a>
        </Section>
        <Section>
          <FormattedMessage id="drv.splashscreen.imprint"
            values={{
              imp: (chunks) => <a href="https://footprinttech.de/imprint-webapp/" target="_blank">{chunks}</a>,
            }} />
        </Section>
      </Footer>
    </Fullscreen>
    );
  } else {
    return (
      <OuterContainer>
        <InnerContainer>
          <ContentContainer>
            <CloseButton type="button" onClick={(ev) => {
              ev.nativeEvent.stopImmediatePropagation();
              emitter.emit(ModalEvents.onCloseModalPress);
            }}>
              <CloseBut src={Images.close} width={34} />
            </CloseButton>
            <ErrorPanel code={error.errorCode} source='PDP' hideAction={true} emitter={emitter} showFooter={false}></ErrorPanel>
          </ContentContainer>
        </InnerContainer>
      </OuterContainer>
    );
  }
}
