import styled from 'styled-components';
import { DarkSlateBlue } from '../../../../theme/colors';
import fptLogo from '../../../../assets/images/driver-small-logo.svg';
export { Fullscreen } from './Screen';

export const ContentPanel = styled.div`
  flex: auto;
  text-align: center;
  padding-bottom: 140px;
`;

export const Footer = styled.div`
  position: absolute;
  font: 12px/1.67 Inter400, sans-serif;
  color: ${DarkSlateBlue};
  bottom: 0;
  margin-bottom:16px;
  padding: 0 49px;
  text-align: center;

  & a {
    font: 12px/1.67 Inter400, sans-serif;
    display:inline;
  }

  & a:any-link {
    color: ${DarkSlateBlue};
    text-decoration: underline;
    display:inline;
  }

  & a:-webkit-any-link {
    color: ${DarkSlateBlue};
    text-decoration: underline;
    display:inline;
  }
`;

export const Section = styled.div`
  margin: 16px 0 0 0;

  &.fpt-hide {
    display: none;
  }
`;

export const FptLogo = styled.img.attrs((props) => ({
  ...props,
  src: fptLogo,
  alt: 'Footprint',
  className: '',
}))`
  margin: 0 auto;
  width: 40px;
  vertical-align: middle;
`;
