export interface ProductData {
  articleName: string;
  articleNumber: string;
  theme: string;
  productSrc?: string;
  apiKey: string;
  sizes: string[];
  clientSessionId?: string;
  mode?: string;
  variant?: number;
  'custom-gv'?: string;
}

export interface ArticleData {
  id: string;
  gtin?: string;
  ean?: string;
  number: string;
  name?: string;
  manufacturer?:string;
  image?: string;
  price?: number;
  currency?: string;
  sizes: string[];
  sum?:string;
}

export interface ArticleResource {
  article_name: string;
  article_number: string;
  gtin: string;
  manufacturer: string;
  shoelastgroup_id: string;
  width_class: string;
}

export interface Matching {
  matching_id?: string;
  measurement_id: string;
  size: any;
  error_code?: string;
  article_number: string;
  article?: any;
  isStoredInSession?: boolean;
}

export interface Recommendation {
  matching_id?: string;
  measurement_id: string;
  session_id?: string;
  size: any;
  error_code?: string;
  article_number: string;
  isStoredInSession?: boolean;
}

export interface MeasurementOutcome {
  measurementId: string;
  sessionId: string;
}

export interface MeasurementProcess {
  articleName?: string;
  articleNumber?: string;
  productSrc?: string;
  email?: string;
  theme: string;
  profile?: string;
  'custom-gv'?: string;
  flow: string;
  pdp?: string;
  steps?: string;
  lang?: string;
  session: string;
  funnel?: string;
  gdpr?: number;
  hasPolling?: boolean;
  end: string;
}

export interface ProductServiceInterface {
  getTarget(): string;
  getApiKey(): string;
  getProductData(): ProductData;
  getArticleData(): ArticleData;
  handleMatching(m: Matching): Promise<any>;
  handleNewMeasurement(): void;
  displayCheckout(m: Matching): void;
  displayExistingMatching(m: Matching): void;
  processMeasurement(d: MeasurementOutcome, s: string): void;
  onCtaButtonClick(): void;
  getArticleId(): string;
  getArticleNumber(): string;
  getClientSessionId():string;
  hasReload(): boolean;
  hasTooltip(): boolean;
  addShopListeners(): void;
  removeShopListeners(): void;
  createSession(): Promise<any>;
  updateSessionId(s: string): string;
  createConversion(m:Matching, a:CONVERSION_ACTION): void;
  readClientCookie(k:string): string | null;
  displaySplashscreen(): Promise<any>;
}

export enum FUNNEL {
  UNDEFINED = 'UNDEFINED', // Will need user input in conversion
  SELF = 'SELF', // Previously known as Adults
  OTHER = 'OTHER', // Previously known as Kids
}

export enum DESKTOP_FLOW_END {
  mobile = 'mobile', // Process redirects to PDP in mobile coming from QR code
  desktop = 'desktop', // Handover to external process, displays recommendation in desktop after scanning QR code
}

export enum MEASUREMENT_END {
  PDP = 'PDP', // Process redirects to PDP in mobile coming from QR code
  EXT = 'EXT', // Handover to external process, displays recommendation in desktop after scanning QR code
}

export const FLOW_END_MAP = {
  mobile: MEASUREMENT_END.PDP,
  desktop: MEASUREMENT_END.EXT,
}

export enum CONVERSION_ACTION {
  MATCHING = 'MATCHING',
  MATCHING_PRIMARY = 'MATCHING_PRIMARY',
  MATCHING_SECONDARY = 'MATCHING_SECONDARY',
  ADDITEM = 'ADDITEM',
  CHECKOUT = 'CHECKOUT',
}

export enum SHOP_PLATFORM {
  CUSTOM = 'CUSTOM',
  SHOPIFY = 'SHOPIFY',
  SHOPWARE = 'SHOPWARE',
}

export enum TOOLTIP_ENABLED {
  INITIAL = 'INITIAL',
  RECOMMENDATION = 'RECOMMENDATION',
  BOTH = 'BOTH',
  NONE = 'NONE',
}

export interface ShopSettings {
  name: string;
  theme?: any;
  desktopFlowEnd?: DESKTOP_FLOW_END;
  steps?: string;
  lang?: string;
  addToCartEnabled?: boolean;
  reloadEnabled?: boolean;
  tooltipEnabled?: TOOLTIP_ENABLED;
  currency?: string;
  funnel?: FUNNEL;
  pricePattern?: string;
  platform?: SHOP_PLATFORM;
  sizePattern?: string;
  hasDefaultArticle?: boolean;
  languageSelectEnabled?: boolean;
  brandLogo?: string;
}

export interface MeasurementParams {
  theme: string;
  flow: string;
  session: string;
  lang?: string;
  gdpr?: number;
  'lang-select'?: number;
}

export enum CurrencyISO4217 {
  CHF = 'CHF',
  GBP = 'GBP',
  EUR = 'EUR',
  JPY = 'JPY',
  USD = 'USD',
}
