import React from 'react';
import styled from 'styled-components';
import { RecommendationComponent } from '../../Recommendation';
import { QRGateway } from '../../QRGateway';
import Splashscreen from '../../Splashscreen/Splashscreen';
import { ErrorPanelStandalone } from '../Error';
import { Processing } from '../Processing';
import { FontsStyle } from '../../FontsStyle';

const ModalRoot = styled.div`
  display: none;
  position: fixed;
  z-index: 3001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(34, 34, 34, 0.75);
  justify-content: center;
  align-items: center;
  letter-spacing: normal;
  font-family: Inter;

  @media (pointer: fine), (pointer: none) {
  	/* desktop */
    height:100%;
  }

  @media (pointer: fine) and (any-pointer: coarse) {
  	/* touch desktop */
    height:100%;
  }

  &.fpt-modal--show {
    display: flex;
  }

  &.fpt-modal--hide {
    display: none;
  }

  & h1 {
    font-family: Inter500;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
  }

  & p {
    line-height:24px;
  }
`;

const ModalContent = styled.div`
  color: #1e4e5f;
  text-align: center;
  width: 100%;
  max-width: 550px;
  @media (max-width: 600px) {
    box-shadow: none;
    border: none;
  }
`;

const UndefinedView = () => { return (<></>)};

export enum ModalView {
  UNDEFINED,
  PROCESSING,
  SPLASHSCREEN,
  QR,
  RECOMMENDATION,
  ERROR,
}

const display = [
  UndefinedView,
  Processing,
  Splashscreen,
  QRGateway,
  RecommendationComponent,
  ErrorPanelStandalone
];

export function Modal(props: any) {

  const { view, emitter, data, visible } = props;
  const isVisible = visible ? 'fpt-modal--show' : 'fpt-modal--hide';
  const DisplayComponent = display[view];

  return (
    <ModalRoot className={'fpt-modal fpt-modal-gradient ' + isVisible}>
      <FontsStyle />
      <ModalContent className="fpt-modal__content">
        <DisplayComponent emitter={emitter} {...data} />
      </ModalContent>
    </ModalRoot>
  );
}
