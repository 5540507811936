import axios, { AxiosInstance } from 'axios';

interface RecommendationData {
  measurement_id: string;
  session_id: string;
  gtin?: string;
  sku?: string;
  tags?: string;
}

class RecommendationService {
  private params: RecommendationData;

  private client: AxiosInstance;

  constructor() {

    this.params = {
      measurement_id: '',
      session_id: '',
    };

    this.client = axios.create({
      baseURL: process.env.API_URL,
    });

  }

  setSessionId(sessionId: string) {
    this.params.session_id = sessionId;
    this.client.defaults.headers.common.Authorization = `Bearer ${sessionId}`;
  }

  setMeasurementId(measurementId: string) {
    this.params.measurement_id = measurementId;
  }

  setGtin(gtin: string) {
    this.params.gtin = gtin;
  }

  setSku(sku: string) {
    this.params.sku = sku;
  }

  setTags(tags: string) {
    this.params.tags = tags;
  }

  createRecommendation() {
    console.log('[driver] create Recommendation called', this.params);
    return this.client.post('/recommendations', this.params);
  }
}

export default new RecommendationService();
