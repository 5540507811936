import React, {
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip';
import RenderUtil from '../../util/RenderUtil';
import storageService from '../../services/StorageService';

const Link = styled.a`
  color: #81bcbb;
  text-decoration: underline;
`

const Hint = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-size: 0.875rem;
  line-height: 20px;
  color: #5f7285;
  opacity: 1;
  margin-top: 10px;
  text-align: center;
`

export const TooltipContainer: FunctionComponent<any> = (props) => {

  const { children, messageId, settings } = props;
  const isTooltipVisible = () => settings.tooltipEnabled ? !storageService.isTooltipViewed(messageId) : false;
  const [tooltipVisible, setTootipVisible] = useState(isTooltipVisible());

  console.log('[driver] tooltip visible', tooltipVisible)

  const closeTooltip = () => {
    console.log('[driver] close called');
    setTootipVisible(false);
    storageService.addViewedTooltip(messageId);
  }

  const refreshTooltip = () => {
    setTootipVisible(isTooltipVisible());
  }

  const buttonId = 'footprinttech-button';

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  let observer = new IntersectionObserver((entries) => {
    console.log('[driver] observer callback called');
    entries.forEach(item => {
      if (!isTooltipVisible()) return;
      setTootipVisible(item.isIntersecting);
    });
  }, options);

  const btn = document.querySelector(`#${buttonId}`);

  if (btn) {
    observer.observe(btn);
  }

  if (!RenderUtil.isButtonVisible()) {
    console.log('[driver] device is not mobile, hiding')
    return <></>
  }

  useEffect(() => {
    window.addEventListener('fpt-reload', refreshTooltip);

    return () => {
      window.removeEventListener('fpt-reload', refreshTooltip);
    }
  }, [])

  // I am not sure why  FormattedMessage is not used here before, i hope this wont cause an issue here. But For multi-lang support I needed.
  return (
    <Tooltip
      onCloseClick={closeTooltip}
      offset={30}
      visible={tooltipVisible}
      containerStyle={{ marginBottom: '0' }}
      messageId={messageId}
    >
      {children}
    </Tooltip>
  )
}
