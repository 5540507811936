import { FootprintTechDriver } from './main/FootprintTech/FootprintTechDriver';
import { TestSettings } from './main/types/TestSettings';

(function (Driver) {
  console.log('[driver] initializing . . .');
  const scriptEl = document.currentScript as HTMLElement;
  console.log('[driver] script element in index', scriptEl);

  const config = {
    apiKey: scriptEl.dataset.apiKey,
  };

  let isButtonRendered = false;

  if (global.testSettings) {
    const testSetting: TestSettings = global.testSettings;
    console.log(
      `[driver] running on test mode for ${testSetting.store.toUpperCase()} on ${testSetting.env}`,
    );
  }

  window.FootprintTechDriver = Driver;

  document.addEventListener('readystatechange', (event) => {

    const target: Document | any = event.target;
    const isCheckout = Driver.isCheckoutPage();
    const hasButton = !!document.querySelector('[data-widget=fpt-button]') ||
      !!document.querySelector('#footprinttech-button');
    const hasBanner = !!document.querySelector('[data-widget=fpt-banner]') ||
     !!document.querySelector('#footprinttech-banner');
     const headlessEnaled = !!document.querySelector('[data-widget=fpt-modal]');

    console.log('[driver] ready state changed', target.readyState);

    if (target.readyState === 'interactive' &&
      !isCheckout &&
      hasButton &&
      !isButtonRendered &&
      !headlessEnaled
    ) {
      console.log('[driver] button rendered interactive');
      Driver.render(config);
      isButtonRendered = true;
    }

    if (target.readyState === 'interactive' &&
      !isCheckout &&
      hasBanner &&
      !headlessEnaled) {
      console.log('[driver] banner initialized interactive');
      Driver.initBanner('footprinttech-banner');
    }

    if (target.readyState === 'complete' &&
      hasButton &&
      !isCheckout &&
      !isButtonRendered &&
      !headlessEnaled) {
      console.log('[driver] ready state complete, rendering the button');
      Driver.render(config);
      isButtonRendered = true;
    }

    if (target.readyState === 'complete' &&
      headlessEnaled) {
      console.log('[driver] ready state complete, rendering modal');
      Driver.renderModal();
    }

    if (target.readyState !== 'complete') return;

    console.log('[driver] ready state complete');

    if (isCheckout) {
      return window.addEventListener('load', () => {
        console.log('[driver] page is fully loaded, calling checkout');
        Driver.handleCheckout()
          .catch(err => console.warn('[driver] no conversion created', err));
      });
    }

  });

  console.log('[driver] document ready state:', document.readyState);

  if (document.readyState === 'complete' &&
    document.querySelector('#footprinttech-button') &&
    !isButtonRendered) {
    console.log('[driver] page is fully loaded, but button not rendered');
    console.log('[driver] renderedering button');
    Driver.render(config);
    isButtonRendered = true;
  } else if (document.readyState === 'complete' && !isButtonRendered) {
    console.warn('[driver] page is fully loaded, but button not rendered');
    console.warn('[driver] unable to find button div element');
    console.warn('[driver] waiting for button div element to render');
    setTimeout(() => {
      console.warn('[driver] trying to render button');
      Driver.render(config);
      isButtonRendered = true;
    }, 1000);
  }

})(FootprintTechDriver);
