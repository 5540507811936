import { colors } from './colors';
import { Images } from '../assets/Images';

const DefaultTheme = {
  name: 'default',
  color: {
    primary: colors['dark-slate-blue'],
    secondary: colors.blackish,
    success: colors.emerald,
    danger: colors['reddish-pink'],
  },
  ctaButton: {
    arrowDisplay: 'inline-block',
    background: colors.white,
    border: '0 none',
    borderRadius: '10px',
    boxShadow: '0 0 2px 0 rgba(30, 78, 95, 0.7)',
    color: colors['dark-slate-blue'],
    filter:
      'invert(26%) sepia(14%) saturate(1718%) hue-rotate(150deg) brightness(97%) contrast(97%)',
    font: 'normal 14px Inter400',
    icon: Images.fptLogoSimple,
    iconHeight: '13.7px',
    label: 'drv.cta-button.label',
    margin: '16px 0',
    minHeight: '45px',
    padding: '0px 8px',
    width: '100%',
    recommendationFont: 'bold 22px Inter900',
  },
  ctaLink: {
    background: 'none',
    font: '1rem UniversalSans600',
    textDecoration: 'underline',
    color: '#5f7285',
    filter: 'none',
    padding: 0,
    margin: '1rem 0',
    hover_textDecoration: 'underline',
    iconHeight: '15px',
  },
  padding: '24px',
  'text-size-large': '25px',
  'text-size-medium': '16px',
  'text-size-small': '14px',
  'text-margin-bottom-large': '37px',
  'btn-bg-color-primary': colors['dark-slate-blue'],
  'btn-color-primary': '#fefffe',
  'btn-border-color-primary': '#322f30',
  'btn-bg-color-secondary': 'transparent',
  'btn-color-secondary': colors['dark-slate-blue'],
  'btn-color-danger': colors.almond,
  'btn-bg-color-danger': colors['reddish-pink'],
  'btn-border-color-secondary': colors['dark-slate-blue'], // '#322f30'
  'btn-border-radius-primary': '50px',
  'btn-border-radius-secondary': '50px',
  'btn-color-link-primary': colors['dark-slate-blue'],
  'btn-bg-color-link-primary': 'transparent',
  'btn-color-link-danger': colors['reddish-pink'],
  'btn-bg-color-link-danger': 'transparent',
  'btn-padding': '17px 50px',
  'btn-padding-top-bottom': '10px',
  tooltip: {
    position: 'TOP',
  }
};

export default DefaultTheme;
