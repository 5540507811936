import React , { CSSProperties }from 'react';
import { Trans } from 'react-i18next';
import './ErrorHeader.css';

interface Props {
  errorIcon: string;
  errorCode: string;
  pageAlignment: string;
  titleTransId: string;
  subTitleTransId: string;
  isFullSizeIcon: boolean;
}

const fptCErrorContainerStyle:CSSProperties = {"display": "flex", "alignItems": "center", "justifyContent": "center", "width": "100%", "zIndex": "2", "marginBottom": "0rem"};
const fptCErrorImageContainerStyle:CSSProperties = {"display": "flex", "justifyContent": "center", "alignItems": "center"};
const fptCErrorHeaderTitleStyle:CSSProperties = {"fontSize": "1.5rem", "marginTop": "1vh", "marginBottom": "0",  "fontWeight": "500", "color": "#1e4e5f","letterSpacing":"normal"};
const fptCErrorHeaderSubtitleStyle:CSSProperties = {"fontFamily": "Inter500", "fontSize": "1rem", "fontWeight": "500", "fontStretch": "normal", "fontStyle": "normal", "lineHeight": "1.5rem", "letterSpacing": "normal", "color": "#1e4e5f"};
// Directly calculate style inside render method
   const windowWidth = window.innerWidth;
   const titleContainerStyle:CSSProperties = windowWidth > 760 ?
     { width: "70%", marginTop: "4vh", marginBottom: "4vh", gap: "1rem", display: "flex", flexDirection: "column", justifyContent: "center" } :
     { width: "100%", marginTop: "4vh", gap: "3rem", display: "flex", flexDirection: "column", justifyContent: "center" };

function ErrorHeader(props?: Props) {
  const dynamicImageStyle:CSSProperties  = {
    ...(props?.isFullSizeIcon ? { width: '100%', height: 'auto' } : { maxWidth: '80px' }),
  };
  // Define a dynamic style for the title container based on pageAlignment
  const dynamicTitleContainerStyle:CSSProperties  = {
    ...(props?.pageAlignment === 'fpt-c-error-centered' ? { textAlign: 'center', alignItems:'center' } : {}),
  };

  return (
    <div style={fptCErrorContainerStyle} className="fpt-c-error-container">
       <div style={{...titleContainerStyle, ...dynamicTitleContainerStyle }} className={`${props?.pageAlignment}`}>
        <div style={fptCErrorImageContainerStyle} className="fpt-c-error-image-container"> {/* Adding container around img */}
          <img
            src={props?.errorIcon}
            style={dynamicImageStyle}
          />
        </div>
        <h1 style={fptCErrorHeaderTitleStyle} className="fpt-c-error-header-title">
          <Trans i18nKey={props?.titleTransId}></Trans>
        </h1>
        <div style={fptCErrorHeaderSubtitleStyle} className="fpt-c-error-header-subtitle">
          <Trans i18nKey={props?.subTitleTransId} values={{ errorCode: props?.errorCode}}></Trans>
        </div>
      </div>
    </div>
  );
}

export default ErrorHeader;
